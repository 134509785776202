import { Box, Button, ButtonProps, useBreakpointValue } from '@chakra-ui/react'
import { useAccountCT } from 'frontastic/contexts/accountCTContext'
import { useFormat } from 'helpers/hooks/useFormat'
import { utagLink } from 'helpers/tealiumHelper'

interface SidebarLogoutProps {
  onClick?: () => void
  buttonProps?: ButtonProps
}

export const SidebarLogout = ({ onClick, buttonProps }: SidebarLogoutProps) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { account } = useAccountCT()

  const triggerEvent = () => {
    utagLink([
      'link',
      {
        tealium_event: 'signout',
        crm_customer_number: account?.externalId || undefined,
        customer_id: account?.accountId || undefined,
        user_loginstatus: !!account?.firstName,
      },
    ])
    onClick?.()
  }
  return (
    <Box width={'full'}>
      <Button
        borderRadius={'6px'}
        height={'40px'}
        margin={'24px 0 0 0'}
        onClick={triggerEvent}
        size={'md'}
        textStyle={'heading-mobile-50'}
        variant={'outline'}
        width={'full'}
        {...buttonProps}
      >
        {formatMessage({ id: 'action.logout' })}
      </Button>
    </Box>
  )
}
