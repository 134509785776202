import { useState, useEffect } from 'react'
import { VStack, Text, Image, Flex, useBreakpointValue } from '@chakra-ui/react'
import { useAccountCT } from 'frontastic/contexts/accountCTContext'
import { semantic } from 'composable/chakra/figma-tokens'
import useContentstack from 'composable/components/hooks/useContentstack'

enum MEMBER_LEVEL {
  GUEST = 'guest',
  MEMBER = 'member',
}

export const SideBarJoinDXLRewards = () => {
  const { getRewardsBannerByMemberLevel } = useContentstack()
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { rewards } = useAccountCT()
  const [bannerContent, setBannerContent] = useState(null)
  // current logic considers opt-in-date to considers if user is a member or not
  const memberLevel = !!rewards?.optInDate ? MEMBER_LEVEL.MEMBER : MEMBER_LEVEL.GUEST

  useEffect(() => {
    const fetchRewardsBanner = async () => {
      // fetch Banner with parameters
      const rewardsBanner = await getRewardsBannerByMemberLevel('component_rewards_banner', memberLevel)
      setBannerContent(rewardsBanner)
    }
    fetchRewardsBanner()
  }, [])

  if (!bannerContent) {
    return <></>
  }

  const bannerTitle = bannerContent?.banner_title ?? ''
  const bannerItems = bannerContent?.reward_items ?? []

  return (
    <VStack
      align="flex-start"
      border={{ base: 'none', md: '1px solid #E2E2E2' }}
      padding="25px"
      spacing="0"
      gap="0"
      alignItems={'stretch'}
      width="100%"
      background="#383434"
      mt={{ base: 6, lg: 9 }}
    >
      <Text
        textStyle={{ base: 'eyebrow-50', md: 'heading-desktop-200' }}
        fontSize="16px"
        color="#C79D27"
        padding="16px 0"
      >
        {bannerTitle.toUpperCase()}
      </Text>
      <VStack align="flex-start" spacing={'0'} gap={'4px'}>
        {bannerItems.map((benefit, index) => (
          <Flex width="100%" marginTop={index !== 0 ? '24px' : '16px'} key={`benefits-${index}`} align={'center'}>
            <Image src={benefit?.icon?.url} alt="benefit icon" boxSize={isMobile ? '24px' : '30px'} />
            <Text
              fontFamily="Libre Franklin"
              fontWeight="400"
              fontSize={isMobile ? '12px' : '14px'}
              color={semantic.text['primary-inverse']}
              marginLeft="20px"
              whiteSpace="pre-line"
            >
              {benefit?.text}
            </Text>
          </Flex>
        ))}
      </VStack>
    </VStack>
  )
}
