import NextLink from 'next/link'
import { Button, ButtonProps, Text, TextProps, Link } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { DEFAULT_ACCOUNT_DASHBOARD_PATH } from '../account/account-dashboard-nav'
import { useLocalStorage } from '../hooks/useLocalStorage'

interface SidebarItemProps {
  href: string
  children?: React.ReactNode
  label?: string
  rootProps?: Omit<ButtonProps, 'children'>
  state?: 'Default' | 'Hover' | 'Active'
  textProps?: TextProps
  isAccountPage?: boolean
}

export const HASH_KEY = 'route_prev_hash'

export const SidebarItem = ({ href, children, label, rootProps, state = 'Default', textProps }: SidebarItemProps) => {
  const isActive = state === 'Active'
  const router = useRouter()
  const currentRoute = router.asPath
  const [_, setHash] = useLocalStorage(HASH_KEY)

  const isDashboardPage = currentRoute.includes(DEFAULT_ACCOUNT_DASHBOARD_PATH)

  const handleRouting = (href: string) => {
    // if the user is on a different page, save the hash they trying to access
    if (!isDashboardPage) {
      const hash = href.split('#')[1]
      setHash(hash)
    }
  }

  return (
    <Link as={NextLink} href={href} passHref onClick={() => handleRouting(href)}>
      <Button
        role={'link'}
        alignItems={'center'}
        backgroundColor={state === 'Hover' ? 'highlight' : 'background'}
        borderRadius={'6px'}
        color={'text'}
        display={'flex'}
        height={{ base: '37px', md: ' 53px' }}
        isActive={isActive}
        justifyContent={'flex-start'}
        padding={{ base: '16px 0', md: '16px 8px' }}
        textDecoration={'none'}
        variant={'ghost'}
        width={'full'}
        _hover={{
          background: 'highlight',
        }}
        _active={{
          color: 'primary',
          textStyle: 'callouts-blockquote-100',
          fontWeight: '700 !important',
          textDecoration: 'none',
          textUnderlineOffset: '8px',
        }}
        {...rootProps}
      >
        {children ? (
          children
        ) : (
          <Text textStyle="callouts-blockquote-100" {...(isActive && { fontWeight: '700' })} {...textProps}>
            {label}
          </Text>
        )}
      </Button>
    </Link>
  )
}
